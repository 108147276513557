import React, { useContext } from "react";
import { Link } from "gatsby";

import { css } from "@emotion/react";
import Close from "../assets/svg/close.inline.svg";

import { ModalRoutingContext } from "../context/modal-routing";

const closeButton = css`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  display: flex;
  padding: 0;
  cursor: pointer;
  svg {
    height: 24px;
    width: 24px;
    fill: #3a383c;
  }
  @media (max-width: 768px) {
    padding: 10px;
    background-color: #fff;
    top: 0;
    right: 0;
    display: none;
  }
`;
const ytCss = css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  #ytembed {
    width: 100%;
    padding-top: 56.625%;
    position: relative;
  }
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const getId = url => {
  var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  var match = url.match(regExp);

  if (match && match[2].length === 11) {
    return match[2];
  } else {
    return 'error';
  }
}

const Video = ({ pageContext }) => {
  const { yt_video } = pageContext
  const { modal, closeTo } = useContext(ModalRoutingContext);
  return (
    <div id="ytembed-container" css={ytCss}>
      <div id="ytembed">
        <iframe src={`//www.youtube.com/embed/${getId(yt_video)}`} title="YouTube video player" allow="autoplay;encrypted-media;"></iframe>
      </div>
      {modal ? (
        <Link
          to={closeTo}
          state={{ closingModal: true, scrollPosition: window.pageYOffset }}
          css={closeButton}
        >
          <Close />
        </Link>
      ) : (
        ""
      )}
    </div>
  )
}

export default Video;

// export const query = graphql`query PdfTemplate($id: String!) {
//   file(id: {eq: $id}) {
//     name
//     localURL
//   }
// }`;
